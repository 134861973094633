<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Detail Berita</span>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440 text-center">
      <div class="container-standar mt-10 mb-10 pad-20">
        <div class="row">
          <div class="col-lg-9 pad-20 berita-content card shadow">
            <h4 class="mb-40 font-34 tittle-berita width-90 text-left font-weight-7 text-dark">
              Event Umroh Bagi WNI Yang Tinggal di Luar Negeri
              <br /> 
            </h4>
            <div class="mb-20 width-100 d-block">
              <social-sharing
                url="https://realtravel.co.id/"
                title="Tittle"
                description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
                quote="Vue is a progressive framework for building user interfaces."
                hashtags="vuejs,javascript,framework"
                twitter-user="vuejs"
                inline-template
              >
                <div>
                  <network network="facebook" class="cursor-pointer d-inline-block float-left ml-0">
                    <img src="@/assets/images/icon/fb.png" alt />
                  </network>
                  <network
                    network="googleplus"
                    class="cursor-pointer d-inline-block float-left ml-2"
                  >
                    <img src="@/assets/images/icon/gplus.png" alt />
                  </network>
                  <network network="twitter" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/tw.png" alt />
                  </network>
                  <network network="whatsapp" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/wa.png" alt />
                  </network>
                  <network network="telegram" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/tele.png" alt />
                  </network>
                  <network network="line" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/line.png" alt />
                  </network>
                </div>
              </social-sharing>
            </div>
            <div class="container-img-detail">
              <img
                class="width-100"
                src="https://storage.realtravel.co.id/news/20191022144841.jpg"
                alt
              />
            </div>

            <p class="text-justify mt-10 font-20">
              <strong>Bagi WNI</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.
            </p>
            <div class="row">
              <div class="col-md-12 text-left">
              <b-tabs content-class="mt-3" fill>
                <b-tab title="Info Event" active>
                   <div class="container">
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and</p>
                   </div>
                </b-tab>
                <b-tab title="Galery">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="card shadow  pad-5 cursor-pointer" v-b-modal.modal-1>
                        <img src="https://maqna.id/wp-content/uploads/2017/06/Maqna.id-FrontRow-Bank-Syariah-Mandiri-BSM-Event-300x169.jpg" alt="">
                      </div>
 

                      <b-modal id="modal-1"  hide-footer="true" size="lg" >
                       <img class="width-100" src="https://maqna.id/wp-content/uploads/2017/06/Maqna.id-FrontRow-Bank-Syariah-Mandiri-BSM-Event-300x169.jpg" alt="">
                      </b-modal>
                    </div>
                    <div class="col-md-3">
                      <div class="card shadow  pad-5 cursor-pointer">
                        <img src="https://maqna.id/wp-content/uploads/2017/06/Maqna.id-FrontRow-Bank-Syariah-Mandiri-BSM-Event-300x169.jpg" alt="">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="card shadow  pad-5 cursor-pointer">
                        <img src="https://maqna.id/wp-content/uploads/2017/06/Maqna.id-FrontRow-Bank-Syariah-Mandiri-BSM-Event-300x169.jpg" alt="">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="card shadow  pad-5 cursor-pointer">
                        <img src="https://maqna.id/wp-content/uploads/2017/06/Maqna.id-FrontRow-Bank-Syariah-Mandiri-BSM-Event-300x169.jpg" alt="">
                      </div>
                    </div>
                    
                  </div>
                </b-tab>
                <!-- <b-tab title="Very, very long title"><p>I'm the tab with the very, very long title</p></b-tab> -->
               
              </b-tabs>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card shadow text-left pad-10">
              <span
                class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
              >Event Terkait</span>
              <router-link to="/event/detail-event">
                <div class="suggest-img">
                  <div class="cont-img-100">
                    <img src="https://storage.realtravel.co.id/news/20191022144841.jpg" alt />
                  </div>
                  <span class="title font-14 font-weight-6">
                    Event Umroh Bagi WNI Yang Tinggal di Luar Negeri
                    <br />
                    <span class="text-grey font-weight-6 mt-2 font-12 d-inline-block">
                      Rabu, 22 October 2019 -
                      <span class="text-primary font-weight-6">Toyiban</span>
                    </span>
                  </span>
                </div>
              </router-link>
               <router-link to="/event/detail-event">
                <div class="suggest-img">
                  <div class="cont-img-100">
                    <img src="https://storage.realtravel.co.id/news/20191022144841.jpg" alt />
                  </div>
                  <span class="title font-14 font-weight-6">
                    Cara Daftar Umroh Bagi WNI Yang Tinggal di Luar Negeri
                    <br />
                    <span class="text-grey font-weight-6 mt-2 font-12 d-inline-block">
                      Rabu, 22 October 2019 -
                      <span class="text-primary font-weight-6">Toyiban</span>
                    </span>
                  </span>
                </div>
              </router-link>
               <router-link to="/event/detail-event">
                <div class="suggest-img">
                  <div class="cont-img-100">
                    <img src="https://storage.realtravel.co.id/news/20191022144841.jpg" alt />
                  </div>
                  <span class="title font-14 font-weight-6">
                    Cara Daftar Umroh Bagi WNI Yang Tinggal di Luar Negeri
                    <br />
                    <span class="text-grey font-weight-6 mt-2 font-12 d-inline-block">
                      Rabu, 22 October 2019 -
                      <span class="text-primary font-weight-6">Toyiban</span>
                    </span>
                  </span>
                </div>
              </router-link>
               
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SocialSharing from "vue-social-sharing";
export default {
  components: {
    SocialSharing
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  }
};
</script>